button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.contactform-wrapper{
    display: grid; 
    grid-template-columns: 1;
    grid-template-rows: 1;
    overflow:hidden;
}

.contactform-footer{
    gap: 1rem;
}

.contactform-step{
    grid-row: 1; 
    grid-column: 1;
    transition: .3s ease;
}

.contactform-inputs-wrapper{
    transition: .3s ease; 
}

.contactform-inputs-wrapper-inactive{
    opacity: 0.5; 
    pointer-events: none;
}

.contactform-checkboxinput-wrapper{
    gap: 1rem;
}

.contactform-checkboxinput-wrapper label{
    cursor: pointer;
    font-size: var(--p-medium);
    font-weight: bold;
}

.contactform-checkboxinput{
    margin-bottom: 0;
    position: relative; 
    height: 1px;
    width: 2rem;
    cursor:pointer;
}

.contactform-checkboxinput::after{
    content: '';
    display: block; 
    width: 1.8rem; 
    height: 1.8rem;
    border-radius: 4px; 
    background-color: white;
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
}

.contactform-checkboxinput:hover::after, .contactform-checkboxinput:focus::after{
    outline: 1px solid black; 
    outline-offset:     2px;   
}

.contactform-checkboxinput::before{
    content: '';
    display: block; 
    width: 2.2rem; 
    height: 2.2rem; 
    background-color: black;
    transition: .1s ease; 
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%) scale(0);
    z-index: 2;
    clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
}
.contactform-checkboxinput:checked::before{ 
    transform: translate(-50%, -50%) scale(1) rotate(-10deg);
}

.contactform-checkboxitems-wrapper{
    display: grid; 
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
}

.contactform-checkboxitem {
    border-radius: 30px;
    padding: 1rem 2rem;
    font-size: var(--p-medium);
    font-weight: 600;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
    background-color: #000;
    color: #fff;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    text-align: left; 
}

.contactform-checkboxitem:hover, .contactform-checkboxitem:focus{
    background-color: #333;
}

.contactform-checkboxitem-active, .contactform-checkboxitem-active:focus{
    background-color: #fff; 
    color: #000;
}

.contactform-checkboxitem.contactform-checkboxitem-active:hover{
    background-color: #e0e0e0;
}

.contactform-textinput{
    font-size: 1.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
    width: 100%;
}

.contactform-textinput:focus{
    box-shadow: 0 0 0 1px #333;
}

.contactform-p-small{
    font-size: 14px;
}

.contactform-submit-button{
    letter-spacing: 0;
    border-radius: 30px;
    padding: 0.5rem 2rem;
    border: none;
    font-size: var(--p-big);
    font-weight: 600;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 20%);
    background-color: black;
    color: white;
    position: relative
}

.contactform-submit-button:hover, .contactform-submit-button:focus{
    background-color: #333;
}

.contactform-submit-button-label{
    transition: .2s ease;
}

.contactform-submit-button-label.hide-contactform-submit-button-label{
    opacity: 0;
}

.contactform-spinner{
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    animation: spin 1s infinite; 
    transition: .3s ease;
    opacity: 0; 
    pointer-events: none; 
}

.contactform-spinner.show-contactform-spinner{
    opacity: 1;
}

.contact-form-skip-button{
    background-color: transparent;
    outline: 1px solid black;
    outline-offset: -1px;
    color: black;     
    box-shadow: none;      
}
.contact-form-skip-button:hover, .contact-form-skip-button:focus{
    color: white;   
    outline: transparent;
}
.contactform-skip-button-inactive{
    opacity: 0.5;
}

.contactform-success-message-icon{
    display: block;
    width: 1.8rem; 
    height: 1.8rem;
    background-color: black;
    clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);

}

.contactform-step-02-seperator{
    margin: 2rem 0; 
    height: 1px; 
    width: 100%;
    background-color: black;

}


.contactform-step-03-success-icon{
    position: relative;
    height: 4.5em;
    width: 4.5rem;
    border-radius: 999px;
    background-color: black;

}

.contactform-step-03-success-icon::after{
    content: '';
    display: block;
    position: absolute; 
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%);
    height: 4rem;
    width: 4rem;
    background-color: white;
    clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);

}

@keyframes spin {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
    
}

.contactform-step-01-inactive{
    transform: translateX(-50%);
    opacity: 0; 
    pointer-events: none; 
}
.contactform-step-02-inactive{
    transform: translateX(50%);
    opacity: 0; 
    pointer-events: none; 
}
.contactform-step-02-inactive-02{
    transform: translateX(-50%);
    opacity: 0; 
    pointer-events: none; 
}
.contactform-step-03-inactive{
    transform: translateX(50%);
    opacity: 0; 
    pointer-events: none; 
}

@media(min-width: 1024px){
    .contactform-checkboxitems-wrapper{
        grid-template-columns: repeat(2, 1fr);
    }
}