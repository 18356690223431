.h00 {
  font-size: 4rem;
  font-size: 5rem;
  font-size: 5rem;
  font-size: var(--h00)
}

.h0 {
  font-size: 3rem;
  font-size: 3rem;
  font-size: 3rem;
  font-size: var(--h0)
}

.h1 {
  font-size: 2rem;
  font-size: 2.5rem;
  font-size: 2.5rem;
  font-size: var(--h1)
}

.h2 {
  font-size: 1.5rem;
  font-size: 2.125rem;
  font-size: 2.125rem;
  font-size: var(--h2)
}

.h3 {
  font-size: 1.25rem;
  font-size: 1.875rem;
  font-size: 1.875rem;
  font-size: var(--h3)
}

.h4 {
  font-size: 1rem;
  font-size: 1.625rem;
  font-size: 1.625rem;
  font-size: var(--h4)
}

.h5 {
  font-size: .875rem;
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-size: var(--h5)
}

.h6 {
  font-size: .75rem;
  font-size: .75rem;
  font-size: .75rem;
  font-size: var(--h6)
}

:root {
  --h00: 4rem;
  --h0: 3rem;
  --h1: 2rem;
  --h2: 1.5rem;
  --h3: 1.25rem;
  --h4: 1rem;
  --h5: .875rem;
  --h6: .75rem
}

.regular {
  font-weight: normal
}

.caps {
  text-transform: uppercase;
  letter-spacing: .2em;
  letter-spacing: .2em;
  letter-spacing: .2em;
  letter-spacing: var(--caps-letter-spacing)
}

.center {
  text-align: center
}

.line-height-4 {
  line-height: 1.5;
  line-height: 1.5;
  line-height: 1.5;
  line-height: var(--line-height-4)
}

.list-reset {
  list-style: none;
  padding-left: 0
}

:root {
  --line-height-1: 1;
  --line-height-2: 1.125;
  --line-height-3: 1.25;
  --line-height-4: 1.5;
  --caps-letter-spacing: .2em;
  --bold-font-weight: bold
}

.overflow-hidden {
  overflow: hidden
}

.max-width-2 {
  max-width: 32rem;
  max-width: 32rem;
  max-width: 32rem;
  max-width: var(--width-2)
}

.border-box {
  box-sizing: border-box
}

:root {
  --width-1: 24rem;
  --width-2: 32rem;
  --width-3: 48rem;
  --width-4: 64rem
}

.align-middle {
  vertical-align: middle
}

.m0 {
  margin: 0
}

.mt0 {
  margin-top: 0
}

.mt1 {
  margin-top: .5rem;
  margin-top: .5rem;
  margin-top: .5rem;
  margin-top: var(--space-1)
}

.mb1 {
  margin-bottom: .5rem;
  margin-bottom: .5rem;
  margin-bottom: .5rem;
  margin-bottom: var(--space-1)
}

.mt2 {
  margin-top: 1rem;
  margin-top: 1rem;
  margin-top: 1rem;
  margin-top: var(--space-2)
}

.mr2 {
  margin-right: 1rem;
  margin-right: 1rem;
  margin-right: 1rem;
  margin-right: var(--space-2)
}

.mb2 {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  margin-bottom: var(--space-2)
}

.ml2 {
  margin-left: 1rem;
  margin-left: 1rem;
  margin-left: 1rem;
  margin-left: var(--space-2)
}

.mx2 {
  margin-left: 1rem;
  margin-left: 1rem;
  margin-left: 1rem;
  margin-left: var(--space-2);
  margin-right: 1rem;
  margin-right: 1rem;
  margin-right: 1rem;
  margin-right: var(--space-2)
}

.mt3 {
  margin-top: 2rem;
  margin-top: 2rem;
  margin-top: 2rem;
  margin-top: var(--space-3)
}

.mb3 {
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  margin-bottom: var(--space-3)
}

.my3 {
  margin-top: 2rem;
  margin-top: 2rem;
  margin-top: 2rem;
  margin-top: var(--space-3);
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  margin-bottom: var(--space-3)
}

.mt4 {
  margin-top: 4rem;
  margin-top: 4rem;
  margin-top: 4rem;
  margin-top: var(--space-4)
}

.mr4 {
  margin-right: 4rem;
  margin-right: 4rem;
  margin-right: 4rem;
  margin-right: var(--space-4)
}

.mb4 {
  margin-bottom: 4rem;
  margin-bottom: 4rem;
  margin-bottom: 4rem;
  margin-bottom: var(--space-4)
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

:root {
  --space-1: .5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem
}

.p0 {
  padding: 0
}

.pt0 {
  padding-top: 0
}

.pb0 {
  padding-bottom: 0
}

.pt1 {
  padding-top: .5rem;
  padding-top: .5rem;
  padding-top: .5rem;
  padding-top: var(--space-1)
}

.pr1 {
  padding-right: .5rem;
  padding-right: .5rem;
  padding-right: .5rem;
  padding-right: var(--space-1)
}

.pb1 {
  padding-bottom: .5rem;
  padding-bottom: .5rem;
  padding-bottom: .5rem;
  padding-bottom: var(--space-1)
}

.pl1 {
  padding-left: .5rem;
  padding-left: .5rem;
  padding-left: .5rem;
  padding-left: var(--space-1)
}

.px1 {
  padding-left: .5rem;
  padding-left: .5rem;
  padding-left: .5rem;
  padding-left: var(--space-1);
  padding-right: .5rem;
  padding-right: .5rem;
  padding-right: .5rem;
  padding-right: var(--space-1)
}

.pt2 {
  padding-top: 1rem;
  padding-top: 1rem;
  padding-top: 1rem;
  padding-top: var(--space-2)
}

.pr2 {
  padding-right: 1rem;
  padding-right: 1rem;
  padding-right: 1rem;
  padding-right: var(--space-2)
}

.pl2 {
  padding-left: 1rem;
  padding-left: 1rem;
  padding-left: 1rem;
  padding-left: var(--space-2)
}

.py2 {
  padding-top: 1rem;
  padding-top: 1rem;
  padding-top: 1rem;
  padding-top: var(--space-2);
  padding-bottom: 1rem;
  padding-bottom: 1rem;
  padding-bottom: 1rem;
  padding-bottom: var(--space-2)
}

.px2 {
  padding-left: 1rem;
  padding-left: 1rem;
  padding-left: 1rem;
  padding-left: var(--space-2);
  padding-right: 1rem;
  padding-right: 1rem;
  padding-right: 1rem;
  padding-right: var(--space-2)
}

.p3 {
  padding: 2rem;
  padding: 2rem;
  padding: 2rem;
  padding: var(--space-3)
}

.pt3 {
  padding-top: 2rem;
  padding-top: 2rem;
  padding-top: 2rem;
  padding-top: var(--space-3)
}

.pr3 {
  padding-right: 2rem;
  padding-right: 2rem;
  padding-right: 2rem;
  padding-right: var(--space-3)
}

.pb3 {
  padding-bottom: 2rem;
  padding-bottom: 2rem;
  padding-bottom: 2rem;
  padding-bottom: var(--space-3)
}

.pl3 {
  padding-left: 2rem;
  padding-left: 2rem;
  padding-left: 2rem;
  padding-left: var(--space-3)
}

.py3 {
  padding-top: 2rem;
  padding-top: 2rem;
  padding-top: 2rem;
  padding-top: var(--space-3);
  padding-bottom: 2rem;
  padding-bottom: 2rem;
  padding-bottom: 2rem;
  padding-bottom: var(--space-3)
}

.px3 {
  padding-left: 2rem;
  padding-left: 2rem;
  padding-left: 2rem;
  padding-left: var(--space-3);
  padding-right: 2rem;
  padding-right: 2rem;
  padding-right: 2rem;
  padding-right: var(--space-3)
}

.pt4 {
  padding-top: 4rem;
  padding-top: 4rem;
  padding-top: 4rem;
  padding-top: var(--space-4)
}

.pb4 {
  padding-bottom: 4rem;
  padding-bottom: 4rem;
  padding-bottom: 4rem;
  padding-bottom: var(--space-4)
}

.px4 {
  padding-left: 4rem;
  padding-left: 4rem;
  padding-left: 4rem;
  padding-left: var(--space-4);
  padding-right: 4rem;
  padding-right: 4rem;
  padding-right: 4rem;
  padding-right: var(--space-4)
}

:root {
  --space-1: .5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem
}

.col-2 {
  width: 16.66667%
}

.col-3 {
  width: 25%
}

.col-4 {
  width: 33.33333%
}

.col-6 {
  width: 50%
}

.col-10 {
  width: 83.33333%
}

.col-11 {
  width: 91.66667%
}

.col-12 {
  width: 100%
}

@media (min-width: 40em) {
  .sm-col-4 {
    width: 33.33333%
  }
}

@media (min-width: 52em) {
  .md-col-3 {
    width: 25%
  }

  .md-col-4 {
    width: 33.33333%
  }

  .md-col-6 {
    width: 50%
  }

  .md-col-8 {
    width: 66.66667%
  }

  .md-col-12 {
    width: 100%
  }
}

@media (min-width: 64em) {
  .lg-col-2 {
    width: 16.66667%
  }

  .lg-col-3 {
    width: 25%
  }

  .lg-col-6 {
    width: 50%
  }
}

.flex {
  display: flex
}

@media (min-width: 52em) {
  .md-flex {
    display: flex
  }
}

@media (min-width: 64em) {
  .lg-flex {
    display: flex
  }
}

.flex-column {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-start {
  align-items: flex-start
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.justify-around {
  justify-content: space-around
}

.flex-auto {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0
}

.relative {
  position: relative
}

.absolute {
  position: absolute
}

.fixed {
  position: fixed
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.z2 {
  z-index: 2;
  z-index: 2;
  z-index: 2;
  z-index: var(--z2)
}

.z4 {
  z-index: 4;
  z-index: 4;
  z-index: 4;
  z-index: var(--z4)
}

:root {
  --z1: 1;
  --z2: 2;
  --z3: 3;
  --z4: 4
}

:root {
  --border-width: 1px;
  --border-radius: 3px
}

@media (max-width: 40em) {
  .xs-hide {
    display: none
  }
}

@media (min-width: 40em)and (max-width: 52em) {
  .sm-hide {
    display: none
  }
}

.h00 {
  font-size: 5rem;
  font-size: 5rem;
  font-size: var(--h00)
}

.h0 {
  font-size: 3rem;
  font-size: 3rem;
  font-size: var(--h0)
}

.h1 {
  font-size: 2.5rem;
  font-size: 2.5rem;
  font-size: var(--h1)
}

.h2 {
  font-size: 2.125rem;
  font-size: 2.125rem;
  font-size: var(--h2)
}

.h3 {
  font-size: 1.875rem;
  font-size: 1.875rem;
  font-size: var(--h3)
}

.h4 {
  font-size: 1.625rem;
  font-size: 1.625rem;
  font-size: var(--h4)
}

.h5 {
  font-size: 1.5rem;
  font-size: 1.5rem;
  font-size: var(--h5)
}

.h6 {
  font-size: .75rem;
  font-size: .75rem;
  font-size: var(--h6)
}

:root {
  --h00: 4rem;
  --h0: 3rem;
  --h1: 2rem;
  --h2: 1.5rem;
  --h3: 1.25rem;
  --h4: 1rem;
  --h5: .875rem;
  --h6: .75rem
}

.regular {
  font-weight: normal
}

.caps {
  text-transform: uppercase;
  letter-spacing: .2em;
  letter-spacing: .2em;
  letter-spacing: var(--caps-letter-spacing)
}

.center {
  text-align: center
}

.line-height-4 {
  line-height: 1.5;
  line-height: 1.5;
  line-height: var(--line-height-4)
}

.list-reset {
  list-style: none;
  padding-left: 0
}

:root {
  --line-height-1: 1;
  --line-height-2: 1.125;
  --line-height-3: 1.25;
  --line-height-4: 1.5;
  --caps-letter-spacing: .2em;
  --bold-font-weight: bold
}

.overflow-hidden {
  overflow: hidden
}

.max-width-2 {
  max-width: 32rem;
  max-width: 32rem;
  max-width: var(--width-2)
}

.border-box {
  box-sizing: border-box
}

:root {
  --width-1: 24rem;
  --width-2: 32rem;
  --width-3: 48rem;
  --width-4: 64rem
}

.align-middle {
  vertical-align: middle
}

.m0 {
  margin: 0
}

.mt0 {
  margin-top: 0
}

.mt1 {
  margin-top: .5rem;
  margin-top: .5rem;
  margin-top: var(--space-1)
}

.mb1 {
  margin-bottom: .5rem;
  margin-bottom: .5rem;
  margin-bottom: var(--space-1)
}

.mt2 {
  margin-top: 1rem;
  margin-top: 1rem;
  margin-top: var(--space-2)
}

.mr2 {
  margin-right: 1rem;
  margin-right: 1rem;
  margin-right: var(--space-2)
}

.mb2 {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  margin-bottom: var(--space-2)
}

.ml2 {
  margin-left: 1rem;
  margin-left: 1rem;
  margin-left: var(--space-2)
}

.mx2 {
  margin-left: 1rem;
  margin-left: 1rem;
  margin-left: var(--space-2);
  margin-right: 1rem;
  margin-right: 1rem;
  margin-right: var(--space-2)
}

.mt3 {
  margin-top: 2rem;
  margin-top: 2rem;
  margin-top: var(--space-3)
}

.mb3 {
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  margin-bottom: var(--space-3)
}

.my3 {
  margin-top: 2rem;
  margin-top: 2rem;
  margin-top: var(--space-3);
  margin-bottom: 2rem;
  margin-bottom: 2rem;
  margin-bottom: var(--space-3)
}

.mt4 {
  margin-top: 4rem;
  margin-top: 4rem;
  margin-top: var(--space-4)
}

.mr4 {
  margin-right: 4rem;
  margin-right: 4rem;
  margin-right: var(--space-4)
}

.mb4 {
  margin-bottom: 4rem;
  margin-bottom: 4rem;
  margin-bottom: var(--space-4)
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

:root {
  --space-1: .5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem
}

.p0 {
  padding: 0
}

.pt0 {
  padding-top: 0
}

.pb0 {
  padding-bottom: 0
}

.pt1 {
  padding-top: .5rem;
  padding-top: .5rem;
  padding-top: var(--space-1)
}

.pr1 {
  padding-right: .5rem;
  padding-right: .5rem;
  padding-right: var(--space-1)
}

.pb1 {
  padding-bottom: .5rem;
  padding-bottom: .5rem;
  padding-bottom: var(--space-1)
}

.pl1 {
  padding-left: .5rem;
  padding-left: .5rem;
  padding-left: var(--space-1)
}

.px1 {
  padding-left: .5rem;
  padding-left: .5rem;
  padding-left: var(--space-1);
  padding-right: .5rem;
  padding-right: .5rem;
  padding-right: var(--space-1)
}

.pt2 {
  padding-top: 1rem;
  padding-top: 1rem;
  padding-top: var(--space-2)
}

.pr2 {
  padding-right: 1rem;
  padding-right: 1rem;
  padding-right: var(--space-2)
}

.pl2 {
  padding-left: 1rem;
  padding-left: 1rem;
  padding-left: var(--space-2)
}

.py2 {
  padding-top: 1rem;
  padding-top: 1rem;
  padding-top: var(--space-2);
  padding-bottom: 1rem;
  padding-bottom: 1rem;
  padding-bottom: var(--space-2)
}

.px2 {
  padding-left: 1rem;
  padding-left: 1rem;
  padding-left: var(--space-2);
  padding-right: 1rem;
  padding-right: 1rem;
  padding-right: var(--space-2)
}

.p3 {
  padding: 2rem;
  padding: 2rem;
  padding: var(--space-3)
}

.pt3 {
  padding-top: 2rem;
  padding-top: 2rem;
  padding-top: var(--space-3)
}

.pr3 {
  padding-right: 2rem;
  padding-right: 2rem;
  padding-right: var(--space-3)
}

.pb3 {
  padding-bottom: 2rem;
  padding-bottom: 2rem;
  padding-bottom: var(--space-3)
}

.pl3 {
  padding-left: 2rem;
  padding-left: 2rem;
  padding-left: var(--space-3)
}

.py3 {
  padding-top: 2rem;
  padding-top: 2rem;
  padding-top: var(--space-3);
  padding-bottom: 2rem;
  padding-bottom: 2rem;
  padding-bottom: var(--space-3)
}

.px3 {
  padding-left: 2rem;
  padding-left: 2rem;
  padding-left: var(--space-3);
  padding-right: 2rem;
  padding-right: 2rem;
  padding-right: var(--space-3)
}

.pt4 {
  padding-top: 4rem;
  padding-top: 4rem;
  padding-top: var(--space-4)
}

.pb4 {
  padding-bottom: 4rem;
  padding-bottom: 4rem;
  padding-bottom: var(--space-4)
}

.px4 {
  padding-left: 4rem;
  padding-left: 4rem;
  padding-left: var(--space-4);
  padding-right: 4rem;
  padding-right: 4rem;
  padding-right: var(--space-4)
}

:root {
  --space-1: .5rem;
  --space-2: 1rem;
  --space-3: 2rem;
  --space-4: 4rem
}

html {
  font-family: "Cera Basic", sans-serif;
  color: #222220;
  color: #222220;
  color: var(--color-4)
}

body {
  margin: 0
}

* {
  margin: 0;
  padding: 0
}

:root {
  --color-black: #000;
  --color-white: #fff;
  --color-1: #ffc800;
  --color-2: #0a82c8;
  --color-3: #f0f5fa;
  --color-4: #222220;
  --color-5: #333;
  --color-6: #363636;
  --color-7: #7a818d;
  --color-8: #f4f4f4;
  --color-9: #e0e0e0;
  --grid-gap: 3rem;
  --grid-gap-sm: 1px;
  --grid-gap-big: 10px;
  --width-1: 24rem;
  --width-2: 32rem;
  --width-3: 48rem;
  --width-4: 56.25rem;
  --width-5: 67.5rem;
  --width-6: 75rem;
  --h000: 9rem;
  --h00: 5rem;
  --h0: 3rem;
  --h1: 2.5rem;
  --h2: 2.125rem;
  --h3: 1.875rem;
  --h4: 1.625rem;
  --h5: 1.5rem;
  --p-big: 1.375rem;
  --p-medium: 1.125rem;
  --p: 1rem;
  --p-small: .875rem;
  --condensed: -3%;
  --condensed-m: 0%;
  --zindexmax: 999999999;
  --lh-p: 1.65
}

.max-width-5 {
  max-width: 67.5rem;
  max-width: 67.5rem;
  max-width: var(--width-5)
}

.max-width-6 {
  max-width: 75rem;
  max-width: 75rem;
  max-width: var(--width-6)
}

.max-height-1 {
  max-height: 24rem;
  max-height: 24rem;
  max-height: var(--width-1)
}

.max-height-2 {
  max-height: 32rem;
  max-height: 32rem;
  max-height: var(--width-2)
}

.max-height-4 {
  max-height: 56.25rem;
  max-height: 56.25rem;
  max-height: var(--width-4)
}

.h00 {
  font-size: 5rem;
  font-size: 5rem;
  font-size: var(--h00);
  line-height: 1;
  letter-spacing: -.1rem
}

.h0 {
  font-size: 3rem;
  font-size: 3rem;
  font-size: var(--h0);
  line-height: 1
}

.h1 {
  font-size: 2.5rem;
  font-size: 2.5rem;
  font-size: var(--h1)
}

.h2 {
  font-size: 2.125rem;
  font-size: 2.125rem;
  font-size: var(--h2)
}

.h3 {
  font-size: 1.875rem;
  font-size: 1.875rem;
  font-size: var(--h3)
}

.h4 {
  font-size: 1.625rem;
  font-size: 1.625rem;
  font-size: var(--h4)
}

.p-big {
  font-size: 1.375rem;
  font-size: 1.375rem;
  font-size: var(--p-big)
}

.p-medium {
  font-size: 1.125rem;
  font-size: 1.125rem;
  font-size: var(--p-medium)
}

.p {
  font-size: 1rem;
  font-size: 1rem;
  font-size: var(--p)
}

.g4 {
  gap: 4rem
}

.h100 {
  height: 100%;
  min-height: 100%
}

.v100 {
  min-height: 99vh;
  height: 99vh
}

.v50 {
  min-height: 50vh;
  height: 50vh
}

.regular {
  font-weight: 400
}

.white {
  color: #fff;
  color: #fff;
  color: var(--color-white)
}

.black {
  color: #000;
  color: #000;
  color: var(--color-black)
}

.color1 {
  color: #ffc800;
  color: #ffc800;
  color: var(--color-1)
}

.color2 {
  color: #0a82c8;
  color: #0a82c8;
  color: var(--color-2)
}

.color3 {
  color: #f0f5fa;
  color: #f0f5fa;
  color: var(--color-3)
}

.color4 {
  color: #222220;
  color: #222220;
  color: var(--color-4)
}

.color7 {
  color: #7a818d;
  color: #7a818d;
  color: var(--color-7)
}

.color8 {
  color: #f4f4f4;
  color: #f4f4f4;
  color: var(--color-8)
}

.bg-color1 {
  background-color: #ffc800;
  background-color: #ffc800;
  background-color: var(--color-1)
}

.bg-color3 {
  background-color: #f0f5fa;
  background-color: #f0f5fa;
  background-color: var(--color-3)
}

.bg-color4 {
  background-color: #222220;
  background-color: #222220;
  background-color: var(--color-4)
}

.bg-color8 {
  background-color: #f4f4f4;
  background-color: #f4f4f4;
  background-color: var(--color-8)
}

.bg-black {
  background-color: #000;
  background-color: #000;
  background-color: var(--color-black)
}

.bg-white {
  background-color: #fff;
  background-color: #fff;
  background-color: var(--color-white)
}

.bg-transparent {
  background-color: transparent
}

.op50 {
  opacity: .5
}

.op100 {
  opacity: 1
}

.align-middle {
  align-items: center
}

.row-reverse {
  flex-direction: row-reverse
}

.grid-3-col {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  grid-gap: 3rem;
  grid-gap: var(--grid-gap)
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit
}

amp-img.contain img {
  -o-object-fit: contain;
  object-fit: contain
}

amp-img.cover img {
  -o-object-fit: cover;
  object-fit: cover
}

amp-img.cover.cover>img {
  -o-object-fit: cover;
  object-fit: cover
}

.gridblock {
  display: grid
}

.gridblock .gridelement {
  grid-column: 1;
  grid-row: 1
}

.rounded-border,
.rounded-border-big {
  border-radius: 24px
}

.btn {
  cursor: pointer;
  display: inline-block


}

.btn-std {
  border-radius: 30px;
  padding: .5rem 1.75rem;
  font-size: 1.375rem;
  font-size: 1.375rem;
  font-size: var(--p-big);
  font-weight: 600;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2)
}

.arrow-heading {
  font-size: 2.125rem;
  font-size: 2.125rem;
  font-size: var(--h2)
}

.arrow-heading:after {
  content: "→";
  display: inline-block;
  margin-right: .5rem;
  color: #ffc800;
  color: #ffc800;
  color: var(--color-1);
  font-size: 2.125rem;
  font-size: 2.125rem;
  font-size: var(--h2)
}

.arrow-heading-small {
  font-size: 1.375rem;
  font-size: 1.375rem;
  font-size: var(--p-big)
}

.arrow-heading-small:after {
  content: "→";
  display: inline-block;
  margin-right: .5rem;
  color: #ffc800;
  color: #ffc800;
  color: var(--color-1);
  font-size: 1.375rem;
  font-size: 1.375rem;
  font-size: var(--p-big)
}

svg.color1 {
  fill: #ffc800;
  fill: #ffc800;
  fill: var(--color-1)
}

svg.color2 {
  fill: #0a82c8;
  fill: #0a82c8;
  fill: var(--color-2)
}

svg.color3 {
  fill: #f0f5fa;
  fill: #f0f5fa;
  fill: var(--color-3)
}

svg.color4 {
  fill: #222220;
  fill: #222220;
  fill: var(--color-4)
}

svg.color7 {
  fill: #7a818d;
  fill: #7a818d;
  fill: var(--color-7)
}

svg.color8 {
  fill: #f4f4f4;
  fill: #f4f4f4;
  fill: var(--color-8)
}

svg.black {
  fill: #000;
  fill: #000;
  fill: var(--color-black)
}

svg.white {
  fill: #fff;
  fill: #fff;
  fill: var(--color-white)
}

.drop-shadow {
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, .2)
}

.top-indent {
  margin-top: -6rem
}

.contact-icon {
  width: 60px;
  height: 60px
}

@media (min-width: 64em) {
  .lg-p4 {
    padding: 4rem
  }

  .lg-px4 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .lg-pt3 {
    padding-top: 2rem
  }

  .lg-pb3 {
    padding-bottom: 2rem
  }

  .lg-pl2 {
    padding-left: 1rem
  }

  .lg-pr2 {
    padding-right: 1rem
  }

  .lg-mt4 {
    margin-top: 4rem
  }

  .lg-mb4 {
    margin-bottom: 4rem
  }
}

@media (min-width: 64em) {
  .lg-p4 {
    padding: 4rem
  }

  .lg-px4 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .lg-pt3 {
    padding-top: 2rem
  }

  .lg-pb3 {
    padding-bottom: 2rem
  }

  .lg-pl2 {
    padding-left: 1rem
  }

  .lg-pr2 {
    padding-right: 1rem
  }

  .lg-mt4 {
    margin-top: 4rem
  }

  .lg-mb4 {
    margin-bottom: 4rem
  }
}

@media (min-width: 52em) {
  .md-p3 {
    padding: 2rem
  }

  .md-px4 {
    padding-left: 4rem;
    padding-right: 4rem
  }

  .md-pl2 {
    padding-left: 1rem
  }

  .md-pr2 {
    padding-right: 1rem
  }

  .md-mb1 {
    margin-bottom: .5rem
  }
}

@media (max-width: 950px) {
  .grid-3-col {
    grid-template-columns: repeat(2, 1fr)
  }
}

@media (max-width: 700px) {
  .px3 {
    padding-left: 1rem;
    padding-right: 1rem
  }
}

@media (max-width: 550px) {
  .grid-3-col {
    grid-template-columns: repeat(1, 1fr)
  }
}

@media (max-width: 500px) {
  :root {
    --h000: 5rem;
    --h00: 3rem;
    --h0: 2.5rem;
    --h1: 2.125rem;
    --h2: 1.875rem;
    --h3: 1.625rem;
    --h4: 1.5rem;
    --h5: 1.375rem;
    --p-big: 1.125rem;
    --p-medium: 1rem;
    --p: 1rem;
    --p-small: .875rem
  }
}

@media (max-width: 350px) {
  :root {
    --h000: 5rem;
    --h00: 3rem;
    --h0: 2.5rem;
    --h1: 1.875rem;
    --h2: 1.875rem;
    --h3: 1.625rem;
    --h4: 1.5rem;
    --h5: 1.375rem;
    --p-big: 1rem;
    --p-medium: 1rem;
    --p: 1rem;
    --p-small: .875rem
  }
}

@media (max-width: 52em) {
  .heroblock {
    display: block
  }
}

p {
  line-height: 1.6rem
}

body * {
  font-family: "cera", sans-serif;
  letter-spacing: 0rem
}

.arrow-heading-small {
  letter-spacing: 0rem
}

p strong {
  font-weight: bold;
  font-family: "cerabold", sans-serif
}

.wp-block-lemon2021-content-wrapper.bg-color1 .arrow-heading-small:after {
  content: "→";
  display: inline-block;
  margin-right: .5rem;
  color: #fff;
  color: #fff;
  font-size: 1.375rem;
  font-size: 1.375rem;
  font-size: var(--p-big)
}

.btn-std {
  padding: 1rem 2rem
}

a.btn.btn-std {
  transition: all .3s ease-in-out;
  letter-spacing: 0
}

a.btn.btn-std:hover {
  transition: all .15s ease-in-out;
  background-color: black;
  color: white
}

a.btn.btn-std.bg-color4:hover {
  transition: all .15s ease-in-out;
  background-color: white;
  color: black
}

.navmenu a::after {
  width: 0%;
  height: 2px;
  content: "";
  display: block;
  position: absolute;
  bottom: -.3rem;
  left: 0rem;
  background-color: #fff;
  transition: all 999ms cubic-bezier(0, 1.085, .015, 1)
}

.navmenu a {
  position: relative;
  top: 0px;
  left: 0px;
  font-size: 1rem;
  font-weight: normal;
  margin-right: 3rem;
  text-transform: uppercase
}

.navmenu a:hover::after {
  width: 100%;
  height: 2px;
  content: "";
  display: block;
  position: absolute;
  bottom: -.3rem;
  left: 0rem;
  background-color: var(--color-1);
  transition: all 999ms cubic-bezier(0, 1.085, .015, 1)
}

.navmenu a[aria-current]:after {
  width: 100%;
  height: 2px;
  content: "";
  display: block;
  position: absolute;
  bottom: -.3rem;
  left: 0rem;
  background-color: var(--color-1);
  transition: all 999ms cubic-bezier(0, 1.085, .015, 1)
}

.navmenu .icons a {
  position: relative;
  top: 0px;
  left: 0px;
  font-size: 1rem;
  font-weight: normal;
  margin-right: 1rem
}

*[role="button"]:hover {
  cursor: pointer
}

#nav-icon2 {
  min-width: 20px;
  position: relative;
  top: 0;
  max-width: 20px;
  max-height: 16px
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #e30613;
  background: var(--color-1);
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  left: 10%
}

a {
  color: #000
}

#nav-icon2 span:first-child {
  top: 0
}

#nav-icon2 span:nth-child(2) {
  top: 6px
}

#nav-icon2 span:nth-child(3) {
  top: 12px
}

.small {
  font-size: .6rem
}

.caps {
  letter-spacing: 0
}

.menulink:hover #nav-icon2 span:first-child {
  top: -3px;
  transition: all .6s ease
}

.menulink:hover #nav-icon2 span:nth-child(2) {
  top: 10px;
  transition: all .6s ease
}

.menulink:hover #nav-icon2 span:nth-child(3) {
  top: 16px;
  opacity: 0;
  transition: all .6s ease;
  transform: rotate(0deg)
}

a.menulink {
  min-height: 32px;
  top: -10px;
  position: relative
}

a.menulink p.small {
  font-size: .2rem
}

a.menulink:hover {
  color: #e30613;
  color: var(--color-1);
  cursor: pointer
}

#header-sidebar .navmenu a {
  margin-bottom: 1rem
}

@media (min-width:64rem) {
  .ampstart-navbar-trigger.close.bold.color2:hover {
    color: var(--color-1);
    transition: all .1s ease-in-out;
    transform-origin: center center;
    opacity: .5
  }
}

.navside1 a {
  font-family: "cerabold", sans-serif;
}

amp-img.contain.logo img {
  object-position: left center
}

div.menu-lang-container {
  display: flex
}

div.menu-lang-container ul {
  display: flex;
  min-width: 100px;
  align-items: center
}

.ampstart-headerbar.small {
  opacity: 0;
  visibility: hidden
}

#markercontainer {
  top: 0;
  left: 0;
  height: 101vh;
  position: absolute;
  z-index: 18201820;
  pointer-events: none
}

#markercontainer #marker {
  position: absolute;
  bottom: -10px;
  width: 1px;
  height: 12px
}

.bg-shadow {
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, .2)
}

.wpcf7-form {
  background-color: white
}

input {
  border-radius: 12px;
  border: 1px solid var(--color-9);
  padding: .5rem;
  margin-bottom: 1rem
}

textarea {
  border-radius: 12px;
  border: 1px solid var(--color-9);
  padding: .5rem;
  margin-bottom: 1rem
}

input:focus-visible {
  border: 1px solid var(--color-1);
  border-radius: 12px;
  box-shadow: 0 0 0 2pt var(--color-1);
  outline: none;
  transition: .3s
}

textarea:focus-visible {
  border: 1px solid var(--color-1);
  border: none;
  border-radius: 12px;
  box-shadow: 0 0 0 2pt var(--color-1);
  outline: none;
  transition: .3s
}

.gridblockS {
  display: grid
}

.gridblockS .gridelementS {
  grid-column: 1;
  grid-row: 1
}

@media (max-width:64rem) {
  .heroblock.v50 {
    max-height: auto;
    height: 100%
  }

  .heroblock.gridblockS {
    min-height: 100vh;
    height: 100%
  }

  .heroblock-content.gridelementS .flex.flex-wrap {
    flex-direction: column-reverse
  }

  .heroblock-content.gridelement h1.white {
    color: var(--color-black)
  }

  #navheader1 div.flex-auto {
    display: none
  }

  #navheader1 a.logo {
    flex-grow: 1
  }

  #navheader1 a.logo amp-img {
    max-height: 64px;
    max-width: 200px;
    padding-left: 16px;
    position: relative;
    left: -24px
  }

  #navheader2 div.flex-auto {
    display: none
  }

  #navheader2 a.logo {
    flex-grow: 1
  }

  #navheader2 a.logo amp-img {
    max-height: 64px;
    max-width: 200px;
    padding-left: 16px;
    position: relative;
    left: -24px
  }
}

section#footer .navmenu a {
  color: white;
  text-transform: unset;
  margin-bottom: .65rem
}

section#footer .small {
  font-size: .86rem
}

section#footer .navmenu.small a {
  font-size: .86rem
}

.bt1 {
  border-top: 1px solid var(--color-7)
}

h1,
h2,
h3,
h4,
.bold {
  font-family: "cerabold", sans-serif;
  line-height: var(--line-height-1);
  font-weight: bold;
}

.h00,
.h0,
.h1,
.h2,
.h3
{
font-family: "cerabold", sans-serif;
letter-spacing: -.1rem
}

a.menulink {
  top: -16px
}

a.logo {
  position: relative;
  top: -6px
}

#navheader2 .logo {
  top: 0px
}

.wpcf7-form {
  background-color: transparent
}

.wpcf7-form-control-wrap input,
.wpcf7-form-control-wrap textarea {
  max-width: 100%;
  min-width: 100%
}

.wpcf7-form .bigform label {
  font-size: 1.3rem;
  width: 100%
}

.wpcf7-form .bigform input {
  font-size: 1.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
  width: 100%
}

.wpcf7-form .bigform textarea {
  font-size: 1.3rem;
  padding-left: 1rem;
  padding-right: 1rem
}

div.wp-block-lemon2021-custom-img.col-12.flex div.col-12.md-col-12 {
  display: flex
}

@media (max-width: 64rem) {

  .wpcf7-form-control-wrap input,
  .wpcf7-form-control-wrap textarea {
    max-width: 80%
  }

  body .h00,
  body .h0,
  body .h1,
  body .h2,
  body .h3 {
    letter-spacing: -.1rem
  }

  .lg-flex.row-reverse {
    display: flex;
    flex-direction: column-reverse
  }

  .menu-lang-container {
    max-width: 80px
  }

  a.menulink {
    width: 80px
  }
}

@media (max-width:64rem) {

  #navheader1 a.logo,
  #navheader2 a.logo {
    flex-grow: 1;
    display: flex;
    justify-content: center
  }
}

a.white.reject.close {
  width: 26px;
  display: block;
  padding: 0;
  text-align: center;
  transform-origin: center center;
  transform: rotate(45deg);
  transition: all .3s ease-in;
  line-height: 26px;
  height: 26px
}

a.white.reject.close:hover {
  transform: rotate(0deg);
  transition: all .3s ease-in
}

input[type="submit"] {
  transition: all .3s ease-in-out;
  letter-spacing: 0;
  border-radius: 30px;
  pointer: cursor;
  padding: .5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: none;
  font-size: 1.375rem;
  font-size: 1.375rem;
  font-size: var(--p-big);
  font-weight: 600;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .2)
}

.bigform input[type="submit"] {
  padding-left: 2rem;
  padding-right: 2rem
}

input[type="submit"]:hover {
  transition: all .15s ease-in-out;
  background-color: black;
  color: white
}

input[type="submit"]:hover {
  cursor: pointer
}

input[type="submit"].black {
  background-color: black;
  color: white
}

input[type="submit"].black:hover {
  background-color: var(--color-4);
  color: white
}

.wp-block-lemon2021-double-feature .row-reverse {
  flex-direction: row
}

.wpcf7-radio {
  display: flex
}

.wpcf7-radio .wpcf7-list-item label {
  display: flex
}

.wpcf7-radio .wpcf7-list-item {
  padding: 0;
  min-width: 100px
}

@media (max-width:52rem) {
  .wp-block-lemon2021-double-feature .row-reverse {
    flex-direction: column
  }
}

.bigform.text2col .md-col-6 {
  width: 49%;
  margin-bottom: 0
}

@media (max-width:48rem) {
  .bigform.text2col .md-col-6 {
    width: 100%;
    margin-bottom: 0
  }
}

.bigform .wpcf7-list-item {
  margin-left: 0;
  padding-left: 0
}

.bigform .wpcf7-radio .wpcf7-list-item {
  margin-left: 0
}

.bigform .wpcf7-radio .wpcf7-list-item label {
  display: flex;
  width: 28px;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  margin-right: 2rem
}

.wpcf7-radio .wpcf7-list-item input {
  min-width: -webkit-fill-available;
  margin-bottom: 0
}

.bigform.col-12 {
  box-sizing: border-box
}

.heroblock-content.gridelementS .flex.flex-wrap.bigform {
  flex-direction: column
}

span.wpcf7-form-control-wrap {
  width: 100%
}

header.ampstart-headerbar {
  max-width: 100vw;
  overflow-x: hidden
}

textarea.wpcf7-form-control,
input.wpcf7-form-control {
  box-sizing: border-box
}

@media (max-width:64rem) {
  div.consentPopup {
    flex-direction: column
  }

  a.menulink {
    margin-right: 0;
    margin-left: 0;
    padding-left: 24px;
    box-sizing: border-box
  }

  a.menu-lang-container {
    width: 80px
  }

  a.logo amp-img {
    padding: 0
  }

  #navheader1 a.logo amp-img {
    max-height: 64px;
    max-width: 200px;
    padding-left: 16px;
    position: relative;
    left: 0px
  }

  #navheader2 a.logo amp-img {
    max-height: 64px;
    max-width: 200px;
    padding-left: 16px;
    position: relative;
    left: 0px
  }
}

a:focus,
a.menulink:focus,
a.ampstart-navbar-trigger:focus,
a.close:focus,
input:focus,
:focus {
  outline: none
}

@media (min-width:48rem) {
  div.heroblock-content.gridelement {
    padding: 0
  }

  div.heroblock-content.gridelement div.max-width-2.my3 {
    max-width: var(--width-6);
    width: 100%;
    box-sizing: border-box;
    padding: var(--space-3);
    margin: 0 auto;
    padding-right: 40%
  }

  div.heroblock-content.gridelementS div.max-width-6.my3 {
    max-width: var(--width-6);
    width: 100%;
    box-sizing: border-box;
    padding: var(--space-3);
    margin: 0 auto
  }
}

@media (min-width:96rem) {
  div.heroblock-content.gridelement div.max-width-2.my3 {
    padding-right: 35%
  }
}

div.heroblock-content.gridelementS div.max-width-6.my3 {
  max-width: var(--width-6);
  width: 100%;
  box-sizing: border-box;
  padding: var(--space-3);
  margin: 0 auto
}

div.heroblock-content.gridelementS div.max-width-6.my3 .wp-block-contact-form-7-contact-form-selector {
  margin-left: 0
}

@media (max-width:48rem) {
  div.heroblock-content.gridelementS {
    max-width: 100vw
  }

  div.heroblock-content.gridelementS div.max-width-6.my3 {
    padding: 0;
    margin: 0 auto
  }

  p.wp-block-lemon2021-lemon-paragraph.p.regular.color4 {
    color: #000
  }
}

.wpcf7-form .bigform input[type="radio"]+span.wpcf7-list-item-label {
  font-size: 1.2rem
}

input::placeholder {
  color: #cbcbcb;
  font-size: 1.1rem
}

textarea::placeholder {
  color: #cbcbcb;
  font-size: 1.1rem
}

@media (max-height:1000px) {
  .gridblockS.heroblock.v100 {
    min-height: 1200px
  }

  .gridblockS.heroblock.v100 .v100 {
    min-height: 1200px
  }
}

amp-img.amp-wp-enforced-sizes {
  -o-object-fit: contain;
  object-fit: contain
}

.amp-wp-default-form-message>p {
  margin: 1em 0;
  padding: .5em
}

.amp-wp-default-form-message[submit-success]>p.amp-wp-form-redirecting,
.amp-wp-default-form-message[submitting]>p {
  font-style: italic
}

.amp-wp-default-form-message[submit-success]>p:not(.amp-wp-form-redirecting) {
  background-color: #90ee90;
  border: 1px solid green;
  color: #000
}

.amp-wp-default-form-message[submit-error]>p {
  background-color: #ffb6c1;
  border: 1px solid red;
  color: #000
}

.amp-wp-default-form-message[submit-success]>p:empty {
  display: none
}

amp-img img,
amp-img noscript {
  image-rendering: inherit;
  -o-object-fit: inherit;
  object-fit: inherit;
  -o-object-position: inherit;
  object-position: inherit
}

body {
  --wp--preset--color--black: #000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #323232;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--color-1: #1064fe;
  --wp--preset--color--color-2: #51cff5;
  --wp--preset--color--color-3: #f5519d;
  --wp--preset--color--color-4: #f7d9d9;
  --wp--preset--color--color-5: #54d56c;
  --wp--preset--color--color-6: #d1f7d0;
  --wp--preset--color--color-7: #d6eaf8;
  --wp--preset--color--gray-1: #a2a7b8;
  --wp--preset--color--gray-2: #76798c;
  --wp--preset--color--gray-3: #a2a7b8;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, #9b51e0 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, #7adcb4 0%, #00d082 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, #cf2e2e 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, #eee 0%, #a9b8c3 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, #4aeadc 0%, #9778d1 20%, #cf2aba 40%, #ee2c82 60%, #fb6962 80%, #fef84c 100%);
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, #ffceec 0%, #9896f0 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, #fecda5 0%, #fe2d2d 50%, #6b003e 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, #ffcb70 0%, #c751c0 50%, #4158d0 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg, #fff5cb 0%, #b6e3d4 50%, #33a7b5 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg, #caf880 0%, #71ce7e 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg, #020381 0%, #2874fc 100%);
  --wp--preset--duotone--dark-grayscale: url("#wp-duotone-dark-grayscale");
  --wp--preset--duotone--grayscale: url("#wp-duotone-grayscale");
  --wp--preset--duotone--purple-yellow: url("#wp-duotone-purple-yellow");
  --wp--preset--duotone--blue-red: url("#wp-duotone-blue-red");
  --wp--preset--duotone--midnight: url("#wp-duotone-midnight");
  --wp--preset--duotone--magenta-yellow: url("#wp-duotone-magenta-yellow");
  --wp--preset--duotone--purple-green: url("#wp-duotone-purple-green");
  --wp--preset--duotone--blue-orange: url("#wp-duotone-blue-orange");
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: .44rem;
  --wp--preset--spacing--30: .67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem
}

:where(.is-layout-flex) {
  gap: .5em
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em
}

.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0
}

.wpcf7 .screen-reader-response:not(#_#_#_#_#_#_#_) {
  word-wrap: normal
}

.wpcf7 form .wpcf7-response-output {
  margin: 2em .5em 1em;
  padding: .2em 1em;
  border: 2px solid #00a0d2
}

.wpcf7 form.init .wpcf7-response-output {
  display: none
}

.wpcf7-form-control-wrap {
  position: relative
}

.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em
}

.wpcf7-list-item-label::before,
.wpcf7-list-item-label::after {
  content: " "
}

@keyframes spin {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

@keyframes blink {
  from {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

.wpcf7 input[type="file"] {
  cursor: pointer
}

.wpcf7 input[type="file"]:disabled {
  cursor: default
}

.wpcf7 .wpcf7-submit:disabled {
  cursor: not-allowed
}

.wpcf7 input[type="url"],
.wpcf7 input[type="email"],
.wpcf7 input[type="tel"] {
  direction: ltr
}

.amp-wp-5dd98e4:not(#_#_#_#_#_) {
  fill: #fff;
  stroke: #282828;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 2px
}

.amp-wp-224b51a:not(#_#_#_#_#_) {
  display: none
}

.amp-wp-3c84ec9:not(#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_#_) {
  display: none;
  visibility: hidden
}

.amp-wp-9a079d0:not(#_#_#_#_#_) {
  line-height: 120%
}